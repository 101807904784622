// extracted by mini-css-extract-plugin
export var footerStyle = "footer-module--footerStyle--pYd6o";
export var logos = "footer-module--logos--n0E9x";
export var icons = "footer-module--icons--3xW3b";
export var icon = "footer-module--icon--FbAp4";
export var title = "footer-module--title--RDnz-";
export var blurb = "footer-module--blurb--3qpQw";
export var copyright = "footer-module--copyright--NzlVR";
export var footerNavList = "footer-module--footerNavList--cQ54n";
export var footerNavListItem = "footer-module--footerNavListItem--dvq2M";
export var links = "footer-module--links--yXqoa";